import { Link } from "gatsby";
import React from "react";

import LayoutLegal from "../../components/LayoutLegal";
import { useDocsMarkdownPages } from "../../queries";

function LegalPage() {
  const pages = useDocsMarkdownPages();

  const legalPages = pages.filter(({ frontmatter: { slug } }) =>
    slug.match(/\/legal/)
  );

  const renderLink = ({ frontmatter: { slug, title } }, i) => (
    <li key={i} className="mb-2">
      <Link to={slug}>{title}</Link>
    </li>
  );

  return (
    <LayoutLegal title="About">
      <section className="flex flex-col items-center md:flex-row">
        <div>
          <h1 className="mb-8 text-4xl">Legal</h1>
          <p className="mb-8">Here you find our different legal policies.</p>

          <ul>
            {legalPages.map(renderLink)}
          </ul>
        </div>
      </section>
    </LayoutLegal>
  );
}

export default LegalPage;
