import { Link } from "gatsby";
import React, { useState } from "react";
import { FaBars, FaGithub, FaTimes, FaUsers } from "react-icons/fa";

import fabriqoLogo from "../../images/fabriqo-logo.svg";
import { useSiteTitelQuery } from "../../queries";
import SearchButton from "./SearchButton";

const Header = (props) => {
  const { navOpen, setNavOpen } = props;
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useSiteTitelQuery();

  return (
    <header className="sticky top-0 z-40 w-full bg-white h-16 md:h-20 backdrop-filter backdrop-blur border-b border-athens-gray text-primary-800">
      <div className="h-full max-w-7xl mx-auto flex items-center">
        <div className="w-full md:flex-shrink-0 md:w-64 px-6 md:px-8 flex items-center h-full">
          <Link to={"/docs"}>
            <img
              alt={"Docs"}
              className="block w-24 text-white-600"
              src={fabriqoLogo}
            />
          </Link>
        </div>
        <div className="flex-1 pr-6 md:px-8">
          <div className="flex items-center justify-between space-x-6 md:space-x-8">
            <div className="w-full">
              {/* <SearchButton /> */}
            </div>
            <div className="hidden md:flex md:items-center md:space-x-3">
              <a
                href={"https://github.com/fabriqo"}
                target="_blank"
                className="hidden md:block text-gray-500 hover:text-gray-600  focus:outline-none transition-colors duration-200"
              >
                <FaGithub className="text-2xl" />
                <span className="sr-only">GitHub</span>
              </a>
              <a
                href={"https://fabriqo.org/community"}
                target="_blank"
                className="hidden md:block text-gray-500 hover:text-gray-600 focus:outline-none transition-colors duration-200"
              >
                <FaUsers className="text-2xl" />
                <span className="sr-only">Fabriqo Organisation Community</span>
              </a>
            </div>

            <a
              href={"https://app.fabriqo.com"}
              target="_blank"
              className="hidden md:flex text-xs py-2 px-4 rounded uppercase tracking-wider box-border focus:outline-none whitespace-nowrap bg-teal-500 text-white focus:bg-opacity-90 hover:bg-opacity-90 disabled:text-gray-600 disabled:bg-teal-300"
            >
              <span className="">Launch Fabriqo</span>
            </a>
            <button
              type="button"
              className="appearance-none focus:outline-none w-12 h-12 flex items-center justify-center md:hidden text-current"
              onClick={() => setNavOpen(open => !open)}
            >
              {navOpen ? (
                <>
                  <FaTimes className="text-2xl" />{" "}
                  <span className="sr-only">Close Menu</span>
                </>
              ) : (
                <>
                  <FaBars className="text-2xl" />{" "}
                  <span className="sr-only">Oen Menu</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
