import React, { useState } from "react";
import SEO from "../seo";

import Header from "./Header";
import SideMenu from "./SideMenu";

const defaultKeywords = [`fashion`, `tech`, `open-source`];
const defaultTitle = 'Fabriqo';

const LayoutDocs = props => {
  const {
    children,
    keywords = defaultKeywords,
    rightSideRenderer = null,
    title = defaultTitle,
  } = props;

  const [navOpen, setNavOpen] = useState(false);

  return (
    <>
      <SEO
        keywords={keywords}
        title={title}
      />
      <div className="w-full max-w-7xl mx-auto relative">
        <Header {...{navOpen, setNavOpen}} />

        <div className="h-full md:flex relative">
          <SideMenu navOpen={navOpen} />
          {/* <main className="flex-1 w-full max-w-4xl px-4 py-8 mx-auto md:px-8 md:py-16"> */}
          <main className="min-w-0 w-full flex-auto md:static md:overflow-visible">
            <div className="px-6 md:px-8 py-12">
              {children}
            </div>
          </main>

          {rightSideRenderer ? rightSideRenderer() : null}
        </div>
      </div>
    </>
  );
};

export default LayoutDocs;
